
import {
  Component, Vue, Prop, Watch,
} from 'vue-property-decorator';
import assetsModule from '@/store/modules/assetsModule';
import { getComponent, getConfigEnv, sleep } from '@/utils/helpers';
import draggable from 'vuedraggable';
import Multiselect from 'vue-multiselect';
import metaDataModule from '@/store/modules/metaDataModule';
import DateRangePicker from 'vue2-daterange-picker';
// you need to import the CSS manually
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import accountModule from '@/store/modules/accountModule';
import tasqsListModule from '@/store/modules/tasqsListModule';
import TasqJob from '@/interfaces/tasqs/TasqJob';
import tasqActionsModule from '@/store/modules/tasqActionsModule';
import FileUpload from '@/components/tasqsCommon/FileUpload.vue';
import { SHOW_ENDPOINT_RESPONSE_ALERT } from '@/lib/constants';
import GenericMixin from '@/lib/mixins/GenericMixin';
import { mixins } from 'vue-class-component';
import workflowModule from '@/store/modules/workflowModule';

@Component({
  components: {
    AppLayout: () => getComponent('common/AppLayout'),
    Multiselect,
    TasqsDetails: () => getComponent('tasqs/TasqsDetails'),
    Dropdown: () => getComponent('common/Dropdown'),
    TasqsMobile: () => getComponent('tasqs/TasqDetailMobile'),
    SpinnerLoader: () => getComponent('loaders/SpinnerLoader'),
    Checklist: () => getComponent('tasqsCommon/Checklist'),
    ConfirmPopup: () => getComponent('tasqsCommon/ConfirmPopup'),
    FileUpload,
    DateRangePicker,
    draggable,
  },
})
export default class AddWorkTicket extends mixins(GenericMixin) {
  @Prop({ type: Object, required: false }) tasq?: TasqJob;

  selectedActions: any = []

  selectedPad: any = null;

   selectedJobCategory: any = null;

   selectedJobType: any = null;

  dataLoadingWells = false;

  dataLoadingWellsFailed = true;

  dataLoadingMetricFailed = true;

  dataLoadingMetric = 3;

  closeFormPopup = false;

  checklist: any = []

  showChecklist = true;

  showFileUpload = true;

  checklistItem: any = ''

  actionErrors: any = []

  selectedWell: any = null;

  windowWidth = window.innerWidth;

  requestDetails = '';

  openTasqCountForAsset = 0;

  openTasqCountForJobCategory = 0;

  openTasqCountForJobType = 0;

  requestCompletionDate: any = new Date();

  workTicketFiles: any = []

  comment = '';

  selectedAssetType = 'Pad';

  isSavingTicket = false;

  updateTicketFiles(files) {
    this.workTicketFiles = files;
  }

  handleBackBtn() {
    if (this.stepNo === 1) {
      this.closeFormPopup = true;
    } else {
      this.stepNo -= 1;
    }
  }

  async handleNextBtn() {
    if (this.isSavingTicket) {
      return;
    }
    this.validateForm();
    if (this.actionErrors && this.actionErrors.length) {
      if (this.actionErrors.includes('selectedWell')) {
        this.$tasqAlert({
          title: 'Form Incomplete',
          message: 'Well is required',
          type: 'error',
        });
      }
      if (this.actionErrors.includes('selectedPad')) {
        this.$tasqAlert({
          title: 'Form Incomplete',
          message: 'Pad is required',
          type: 'error',
        });
      }
      return;
    }
    if (this.stepNo < 3) {
      this.stepNo += 1;
    } else {
      await this.createNewWorkTicket();
      // this.$emit('close-add-work-ticket');
      // this.stepNo = 1;
    }

    if (this.stepNo === 2) {
      await this.fetchWellData();
    }
  }

  updateChecklist(list) {
    this.checklist = list;
  }

  workTicketPrioritySteps = [

    {
      title: 'Is there a safety concern?',
      checked: false,
      hasDetailSection: true,
      name: 'safetyConcernRadioGroup',
      placeholder: 'Safety concern details here ...',
      options: [{ value: true, label: 'Yes' }, { value: false, label: 'No' }],
      details: '',
    },

    {
      title: 'Is there an environmental concern?',
      checked: false,
      hasDetailSection: true,
      name: 'environmentalConcernRadioGroup',
      placeholder: 'Environmental concern details here ...',
      options: [{ value: true, label: 'Yes' }, { value: false, label: 'No' }],
      details: '',
    },

    {
      title: 'Is the issue in the work ticket causing a production loss?',
      checked: false,
      hasDetailSection: false,
      hasDefermentSection: true,
      dailyOilLossValue: '',
      dailyGasLossValue: '',
      name: 'tiedToDefermentRadioGroup',
      placeholder: 'Environmental concern details here ...',
      options: [{ value: true, label: 'Yes' }, { value: false, label: 'No' }],
      details: '',
    },

    {
      title: 'Priority',
      checked: 'Low',
      hasDetailSection: false,
      name: 'priorityRadioGroup',
      placeholder: 'Environmental concern details here ...',
      options: ['Highest', 'High', 'Medium', 'Low', 'Lowest'],
      details: '',
    },

  ]

  selectedPerson = { value: workflowModule.user.email, text: `${workflowModule.user.firstName} ${workflowModule.user.lastName}` };

  stepNo = 1;

  similarTasqs: any = [];

  categoryOptions: any = []

  actionItems: any = []

  get getStepHeader() {
    if (this.stepNo === 2) {
      return 'Add Work Ticket Priority';
    } if (this.stepNo === 3) {
      return 'Review work ticket';
    }
    return 'Add new work ticket';
  }

  get enabledPads() {
    return assetsModule.enabledPads;
  }

  get enabledWells() {
    return assetsModule.enabledWells;
  }

  get enabledWellsMapping() {
    return assetsModule.enabledWells.map((well) => ({ text: well.text, value: well.value }));
  }

  get workTicketCategories() {
    return JSON.parse(getConfigEnv('WORK_TICKET_JOB_CATEGORIES'));
  }

  get jobCategories() {
    return this.workTicketCategories.map((item) => item.title).sort();
  }

  get jobTypes() {
    const jobCategory = this.workTicketCategories.find((item) => item.title === this.selectedJobCategory);
    if (jobCategory) {
      return jobCategory.Options.sort();
    }
    return {};
  }

  get priorityText() {
    return this.workTicketPrioritySteps.find((step) => step.name === 'priorityRadioGroup')?.checked;
  }

  get safetyConcermText() {
    const safetyItem = this.workTicketPrioritySteps.find((step) => step.name === 'safetyConcernRadioGroup');
    if (safetyItem && safetyItem.checked) {
      return safetyItem.details;
    }
    return 'No';
  }

  get environmentalConcernText() {
    const safetyItem = this.workTicketPrioritySteps.find((step) => step.name === 'environmentalConcernRadioGroup');
    if (safetyItem && safetyItem.checked) {
      return safetyItem.details;
    }
    return 'No';
  }

  checkMedia(e) {
    console.log(e);
  }

  async created() {
    this.dataLoadingWells = true;
    this.dataLoadingWellsFailed = false;
    const promiseList: any = [];
    this.selectedJobCategory = this.jobCategories ? this.jobCategories[0] : null;
    this.selectedJobType = this.jobTypes ? this.jobTypes[0] : null;
    promiseList.push(assetsModule.getEnabledWells());
    promiseList.push(assetsModule.getEnabledPads());

    Promise.all(promiseList).then(async () => {
      // if (this.tasq && this.tasq.wellName) {
      //   this.selectedWell = { text: this.tasq.wellName, value: this.tasq.wellName };
      // }
      // console.log('resolved');
      // await this.fetchActions();
      this.dataLoadingWells = false;
    }, (err) => {
      console.log('Error:');
      this.dataLoadingWellsFailed = true;
      console.log(err);
    });
  }

  get isMobile() {
    return this.windowWidth < 768;
  }

  validateForm() {
    this.actionErrors = [];
    if (this.selectedAssetType === 'Well') {
      if (!this.selectedWell) {
        this.actionErrors.push('selectedWell');
      }
    }

    if (this.selectedAssetType === 'Pad') {
      if (!this.selectedPad) {
        this.actionErrors.push('selectedPad');
      }
    }
  }

  assignPerson() {
    this.actionErrors = [];

    this.validateForm();

    if (this.actionErrors.length) {
      return;
    }

    this.stepNo = 1;
  }

  async fetchWellData() {
    try {
      this.dataLoadingMetric = 3;
      this.dataLoadingMetricFailed = false;
      let well = '';
      if (this.selectedAssetType === 'Pad') {
        well = this.selectedPad.value;
      } else {
        well = this.selectedWell.value;
      }
      tasqsListModule.getCountOpenTasqsForAsset({ assetName: well }).then((countForWell) => {
        if (!Number.isNaN(countForWell)) {
          this.openTasqCountForAsset = countForWell;
        }
        this.dataLoadingMetric -= 1;
      });
      tasqsListModule.getCountOpenTasqsForJob({ assetName: well, filterBy: 'JobType', filterByValue: this.selectedJobType }).then(
        (countForJobType) => {
          if (!Number.isNaN(countForJobType)) {
            this.openTasqCountForJobType = countForJobType;
          }
          this.dataLoadingMetric -= 1;
      });
      tasqsListModule.getCountOpenTasqsForJob({ assetName: well, filterBy: 'JobCategory', filterByValue: this.selectedJobCategory }).then(
        (countForJobCategory) => {
          if (!Number.isNaN(countForJobCategory)) {
            this.openTasqCountForJobCategory = countForJobCategory;
          }
          this.dataLoadingMetric -= 1;
      });
    } catch (error) {
      this.dataLoadingMetricFailed = true;
      console.error(error);
    }
  }

  async createNewWorkTicket() {
    try {
      this.isSavingTicket = true;

      this.actionErrors = [];

      this.validateForm();
      let well = '';

      if (this.selectedAssetType === 'Pad') {
        well = this.selectedPad.value;
      } else {
        well = this.selectedWell.value;
      }
      const predictionID = await tasqActionsModule.addWorkTicket({
      // eslint-disable-next-line max-len
        assetType: this.selectedAssetType,
        well,
        jobType: this.selectedJobType,
        jobCategory: this.selectedJobCategory,
        checklist: this.checklist,
        files: this.workTicketFiles,
        requestDetails: this.requestDetails,
        requestCompletionDate: this.requestCompletionDate,
        details: this.workTicketPrioritySteps,
        priority: this.priorityText,
      });

      await tasqsListModule.getRawTasq({ PredictionID: predictionID, Type: 'workTicket' });

      if (this.$route.name !== 'WorkTicketCalendar') {
        this.$router.push({
          name: 'WorkTickets',
          params: {
            id: predictionID || '',
          },
          query: { type: 'workticket' },
        });
      }
      tasqsListModule.setActiveWorkTicket(this.tasq);

      this.isSavingTicket = false;
      this.$emit('close-add-work-ticket');
      this.$eventBus.$emit(SHOW_ENDPOINT_RESPONSE_ALERT, { text: 'Ticket saved successfully!', error: false });
      console.log(predictionID);
    } catch (error) {
      this.$eventBus.$emit(SHOW_ENDPOINT_RESPONSE_ALERT, { text: 'Ticket Saving Failed!', error: true });
      this.isSavingTicket = false;
      console.error(error);
    }
  }
}

